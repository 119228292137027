import { ActivationMethod, getUserActivationMethods } from "resources/AudiotekaApi";
import { useUser } from "store/useUser";
import { useEffect, useState } from "react";

export function useActivationMethods(visitorMethods: ActivationMethod[]) {
  const user = useUser((state) => state.data);
  const isUserFetched = useUser((state) => state.status === "fetched");

  const [userMethods, setUserMethods] = useState<ActivationMethod[]>(null);

  useEffect(() => {
    setUserMethods(null);

    if (user) {
      getUserActivationMethods().then(({ data }) => {
        setUserMethods(data.methods);
      });
    }
  }, [user?.id]);

  return {
    activationMethods: userMethods ?? visitorMethods,
    isLoading: !isUserFetched || (user && !userMethods),
  };
}
