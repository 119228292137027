import React from "react";
import classNames from "classnames";

import { ButtonLink } from "ui/button/button-link";
import { Text } from "ui/text";
import { HtmlToReact } from "components/html-to-react";
import { ActivationMethod } from "resources/AudiotekaApi";

import { getDurationBasedOnType } from "../offer-boxes.utils";
import { Argumenters } from "./argumenters/argumenters";
import { PriceBox } from "./price-box/price-box";
import css from "./offer-box.module.scss";

interface Props {
  method: ActivationMethod;
}

export function OfferBox({ method }: Props) {
  const isHighlighted = !!method.purchase_box_highlight;
  const duration = getDurationBasedOnType(method.offer_type);

  return (
    <div className={css.offerBox}>
      <div className={classNames(css.promoWrapper, { [css.active]: isHighlighted })}>
        <Text className={css.promoText} type="subtitleNormal">
          {method.purchase_box_highlight}
        </Text>
        <div className={classNames(css.box, { [css.active]: isHighlighted })}>
          <Text as="p" className={css.title} type="heading5">
            {method.plan_name}
          </Text>
          <Text className={css.description} type="bodyNormal">
            {method.purchase_box_paragraph_header}
          </Text>
          <PriceBox
            basePrice={method.display_price}
            promoPrice={method.promo_display_price}
            initialPrice={method.initial_price}
            duration={duration}
          />
          <Argumenters argumenters={method.purchase_box_argumenters} />
          <ButtonLink
            className={css.action}
            color={isHighlighted ? "club" : "white"}
            href={method.web_payment_url}
            outlined={!isHighlighted}
          >
            {method.purchase_box_button_label || "Wybierz"}
          </ButtonLink>
        </div>
      </div>
      <Text className={css.asterisk} type="bodyNormal">
        {method.purchase_box_paragraph_footer ? <HtmlToReact html={method.purchase_box_paragraph_footer} /> : null}
        {method.lowest_price ? <HtmlToReact html={method.lowest_price} /> : null}
      </Text>
    </div>
  );
}
