import React from "react";
import classNames from "classnames";

import { Text } from "ui/text";
import { ActivationMethod } from "resources/AudiotekaApi";
import { pluralString } from "utils/pluralString";

import css from "./price-box.module.scss";

interface Props {
  basePrice: ActivationMethod["display_price"];
  promoPrice: ActivationMethod["promo_display_price"];
  initialPrice: ActivationMethod["initial_price"];
  duration: number;
}

export function PriceBox({ basePrice, promoPrice, initialPrice, duration = 1 }: Props) {
  const isPromo = !!promoPrice;

  return (
    <div className={css.box}>
      <Text as="p" className={classNames(css.priceRow, { [css.promo]: isPromo })} type="heading4">
        {isPromo && (
          <Text as="span" className={css.oldPrice} type="bodyNormal">
            {basePrice}
          </Text>
        )}
        {initialPrice ? initialPrice.display_price : isPromo ? promoPrice : basePrice}
        <Text as="span" className={css.duration} type="heading6">
          / {duration > 1 ? duration : null}{" "}
          {pluralString(duration, { one: "miesiąc", few: "miesiące", more: "miesięcy" })}
        </Text>
      </Text>
      {initialPrice ? (
        <Text className={css.info} type="subtitleNormal">
          przez {initialPrice.duration_in_months}{" "}
          {pluralString(initialPrice.duration_in_months, { one: "miesiąc", few: "miesiące", more: "miesięcy" })},
          później {basePrice} / miesiąc
        </Text>
      ) : null}
    </div>
  );
}
